<template>
  <div class="page-cu-container">
    <div class="page-cu-top">
      <a-form layout="inline">
        <a-form-item>
          <a-input v-model="page.key" placeholder="请输入关键字"></a-input>
        </a-form-item>

        <a-form-item>
          <a-select style="width: 120px" v-model="page.grant_year">
            <a-select-option value=""> 请选择年 </a-select-option>
            <a-select-option
              v-for="(item, index) in year"
              :key="index"
              :value="item"
              >{{ item }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-select style="width: 120px" v-model="page.grant_month">
            <a-select-option value=""> 请选择月 </a-select-option>
            <a-select-option
              v-for="(item, index) in month"
              :key="index"
              :value="item"
              >{{ item }}</a-select-option
            >
          </a-select>
        </a-form-item>

        <a-form-item>
          <a-button type="primary" @click="getList" icon="search"
            >搜索</a-button
          >
        </a-form-item>
<!--        <a-form-item>-->
<!--          <a-button-->
<!--            :icon="getExicon.icon"-->
<!--            :disabled="getExicon.ed"-->
<!--            @click="$exportFun('/export/pageCompensationUsers', page)"-->
<!--            >导出</a-button-->
<!--          >-->
<!--        </a-form-item>-->
      </a-form>
    </div>
    <div class="page-cu-main">
      <a-table
        :columns="columns"
        :data-source="list"
        rowKey="id"
        bordered
        :pagination="false"
      >
        <template slot="state" slot-scope="text">
          <a-tag color="orange" v-if="text == 1"> 待审核 </a-tag>
          <a-tag color="green" v-if="text == 2"> 审核通过 </a-tag>
          <a-tag color="red" v-if="text == 3"> 审核拒绝 </a-tag>
        </template>
        <template slot="idno_positive_img_url" slot-scope="text">
          <view-file :data="text"></view-file>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-button type="link" size="small" @click="() => openUserlist(record)"
            >查看详情</a-button
          >
        </template>
      </a-table>
    </div>
    <div class="page-cu-pagination">
      <a-pagination
        :page-size.sync="page.pageSize"
        :total="page.totalRow"
        v-model="page.start"
        @change="changePage"
      />
    </div>
    <div class="page-cu-pagination"></div>
    <!-- 查看人员 -->
    <a-modal
      title="查看详情"
      width="1600px"
      :visible="vUserVisible"
      @ok="vUserVisible = false"
      ok-text="确认"
      cancel-text="取消"
      @cancel="vUserVisible = false"
      :confirmLoading="$store.state.requestLoading"
    >
      <div class="page-cu-top" style="padding-top: 0px">
        <a-form layout="inline">
          <a-form-item>
            <a-input
              v-model="vUserPage.key"
              placeholder="请输入关键字"
            ></a-input>
          </a-form-item>
          <a-form-item>
            <a-select
              style="width: 350px"
              v-model="vUserPage.project_id"
              placeholder="请选择项目"
              show-search
              :filter-option="filterOption"
            >
              <a-select-option value="">请选择项目</a-select-option>
              <a-select-option
                v-for="(item, index) in proJson"
                :key="index"
                :value="item.id"
                :title="item.name"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-select
              v-if="platformCode"
              style="width: 150px"
              v-model="vUserPage.team_id"
              placeholder="请选择劳务队"
            >
              <a-select-option value="">请选择劳务队</a-select-option>
              <a-select-option
                v-for="(item, index) in teamJson"
                showSearch
                :key="index"
                :value="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-select
              style="width: 120px"
              v-model="vUserPage.work_id"
              placeholder="请选择工种"
            >
              <a-select-option value="">请选择工种</a-select-option>
              <a-select-option
                v-for="(item, index) in jobJson"
                showSearch
                :key="index"
                :value="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-select style="width: 120px" v-model="vUserPage.grant_year">
              <a-select-option value=""> 请选择年 </a-select-option>
              <a-select-option
                v-for="(item, index) in year"
                :key="index"
                :value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-select style="width: 120px" v-model="vUserPage.grant_month">
              <a-select-option value=""> 请选择月 </a-select-option>
              <a-select-option
                v-for="(item, index) in month"
                :key="index"
                :value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-input
              v-model="vUserPage.start_price"
              placeholder="金额开始"
              style="width:100px"
            ></a-input
            >-<a-input
              v-model="vUserPage.end_price"
              style="width:100px"
              placeholder="金额结束"
            ></a-input>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="getUserDetail" icon="search"
              >搜索</a-button
            >
          </a-form-item>
          <a-form-item>
            <a-button
              :icon="getExicon.icon"
              :disabled="getExicon.ed"
              @click="
                $exportFun('/export/pageCompensationUserDetail', vUserPage)
              "
              >导出</a-button
            >
          </a-form-item>
        </a-form>
      </div>
      <a-table
        :columns="vUserColumns"
        :data-source="vUserList"
        :pagination="{
          total: vUserPage.totalRow,
          defaultPageSize: vUserPage.limit,
          defaultCurrent: 1,
        }"
        :scroll="{ x: 1000, y: 500 }"
        bordered
        rowKey="id"
      >
        <template slot="grant_year" slot-scope="text, record">
          {{ record.grant_year }}年{{ record.grant_month }}月
        </template>
      </a-table>
    </a-modal>
    <a-modal
      v-model="imgvisible"
      title="预览"
      ok-text="确认"
      @ok="hideModal"
      :footer="null"
    >
      <img
        style="width: 100%"
        :src="viewImgData + '?x-oss-process=image/resize,m_fixed,h_400,w_400'"
        alt=""
      />
    </a-modal>
  </div>
</template>

<script>
import * as Api from "./api";
export default {
  name: "index",
  data() {
    return {
      columns: [
        {
          title: "姓名",
          dataIndex: "name",
        },
        {
          title: "身份证号",
          dataIndex: "idno",
        },
        {
          title: "手机号",
          dataIndex: "mobile",
        },
        {
          title: "性别",
          dataIndex: "sex",
        },

        {
          title: "身份证正面",
          dataIndex: "idno_positive_img_url",
          key: "idno_positive_img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          width: 120,
        },
        {
          title: "身份证反面",
          dataIndex: "idno_reverse_img_url",
          key: "idno_reverse_img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          width: 120,
        },
        {
          title: "生活照",
          dataIndex: "head_img_url",
          key: "head_img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          width: 120,
        },
        {
          title: "应发放总金额",
          dataIndex: "grant_fee",
        },
        {
          title: "扣个税总金额",
          dataIndex: "personal_income_tax_fee",
        },
        {
          title: "实际发放总金额",
          dataIndex: "practical_grant_fee",
        },

        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
          width: 100,
        },
      ],
      viewImgData: "",
      platformCode: window.localStorage.getItem("platformCode") != "labor_team",
      imgvisible: false,
      isAdd: true, // TODO: 是否是新增操作
      page: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20,
        state: 2,
        key: "",
        grant_year: "",
        grant_month: "",
      },
      list: [],
      actionVisible: false,
      record: "",
      menuList: [],
      showTree: false,
      expandedKeys: [],
      autoExpandParent: true,
      treeFields: {
        children: "children",
        title: "name",
        key: "id",
      },
      selectedIds: [],
      selectHalf: [],
      submitFlag: true,
      // 解决数据树父子节点选中的问题
      checkedKeys: { checked: [], halfChecked: [] },
      proJson: [],
      vUserVisible: false,
      vUserPage: {
        start: 1,
        totalRow: 0,
        pageSize: 20,
        limit: 20,
        user_id: "",
        key: "",
        team_id: "",
        project_id: "",
        work_id: "",
        grant_year: "",
        grant_month: "",
        start_price: "",
        end_price: "",
      },
      vUserColumns: [
        {
          title: "姓名",
          dataIndex: "uname",
          width: 80,
        },
        {
          title: "身份证",
          dataIndex: "idno",
        },
        {
          title: "银行卡号",
          dataIndex: "bank_card",
        },

        {
          title: "所属项目",
          dataIndex: "pname",
        },
        {
          title: "劳务队",
          dataIndex: "tname",
        },
        {
          title: "工种",
          dataIndex: "wname",
          width: 80,
        },
        {
          title: "应发放费用",
          dataIndex: "grant_fee",
          width: 105,
        },
        {
          title: "个税金额",
          dataIndex: "personal_income_tax_fee",
          width: 100,
        },
        {
          title: "实际发放费用",
          dataIndex: "practical_grant_fee",
          width: 120,
        },
        {
          title: "工资年月",
          dataIndex: "grant_year",
          scopedSlots: { customRender: "grant_year" },
          width: 105,
        },
        {
          title: "审核人",
          dataIndex: "company_audit_name",
        },
      ],
      vUserList: [],
      year: [],
      month: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      teamJson: [],
      jobJson: [],
    };
  },
  computed: {
    getExicon() {
      return this.$store.state.EXICON;
    },
  },
  mounted() {
    this.getList();
    this.getProJson();
    this.getWorkJson();
    this.getTeamJson();
    this.$nextTick(function () {
      let date = new Date();
      let y = date.getFullYear() + 1; //获取完整的年份(4位)
      for (let i = 0; i < 30; i++) {
        y = y - 1;
        this.year.push(y);
      }
    });
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 列表
    async getList() {
      let res = await Api.List({ ...this.page });
      this.page.totalRow = res.page.totalRow;
      this.page.pageSize = res.page.pageSize;
      this.list = res.page.list;
    },

    async getProJson() {
      let res = await Api.getProJson({ limit: 100000, state: "1" });
      this.proJson = res.page.list;
    },
    // 查看审核数据下人员
    openUserlist(data) {
      this.vUserPage.user_id = data.id;
      this.vUserVisible = true;
      this.getUserDetail();
    },
    // 获取工种下拉数据
    async getWorkJson() {
      let res = await Api.getWorkJson({ limit: 100000, state: "1" });
      this.jobJson = res.list;
    },
    // 获取劳务队下拉数据
    async getTeamJson() {
      let res = await Api.getTeamJson({ limit: 100000, state: "1" });
      this.teamJson = res.page.list;
    },

    async getUserDetail() {
      let res = await Api.UserDetail({ ...this.vUserPage });
      if (res && res["code"] == "0") {
        this.vUserList = res.page.list;
      } else {
        this.vUserList = [];
        this.$message.error(res.message);
      }
    },
    changePage(currentPage) {
      this.page.start = currentPage;
      this.getList();
    },
    hideModal() {
      this.imgvisible = false;
    },
  },
};
</script>

<style scoped>
</style>
