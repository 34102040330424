import request from '@/utils/request';

// 列表
export function List(params) {
    return request({
        url: '/admin/compensationUser/pageCompensationUsers',
        method: 'GET',
        params
    });
}
// 获取项目下拉数据
export function getProJson(params) {
    return request({
        url: '/admin/project/page',
        method: 'GET',
        params
    });
}
// 查看人员
export function UserDetail(params) {
    return request({
        url: '/admin/compensationUser/pageCompensationUserDetail',
        method: 'GET',
        params
    });
}
// 获取工种下拉数据
export function getWorkJson(params) {
    return request({
        url: '/admin/project/getWorkJson',
        method: 'GET',
        params
    });
}
// 获取劳务队下拉数据
export function getTeamJson(params) {
    return request({
        url: '/admin/team/page',
        method: 'GET',
        params
    });
}